export interface Props {
  atmBaseUrl: string;
  subscriptionId: string;
}

export interface Meta {
  id: string;
  uri: string;
  created: string;
  updated: string;
}

export interface Status {
  code: number;
}

export interface Product {
  id: string;
  name: string;
}

export interface Plan {
  id: string;
  amount: number;
  currency: string;
  interval: string;
  interval_count: number;
  trial_period_days: number;
  product: Product;
}

enum CardBrand {
  // Should match ATM's `brand` schema choices.
  amex = "amex",
  diners = "diners",
  discover = "discover",
  jcb = "jcb",
  mastercard = "mastercard",
  unionpay = "unionpay",
  visa = "visa",
  unknown = "unknown",
}

export interface PaymentMethod {
  id: string;
  brand: CardBrand;
  exp_month: number;
  exp_year: number;
  last4: string;
}

export interface Transaction {
  _meta: Meta;
  id: string;
  patronEmail?: string;
  amount?: number;
  detailUuid?: string;
  // shipping
  completedOn?: string;
  notificationSentOn?: string;
  state?: string;
  orderId?: string;
  invoiceId?: string;
  errors?: Array<object>;
  // lineItems: Array<LineItem>;
  customerToken?: string;
  paymentIntentClientSecret?: string;
}

export interface Charge {
  id: string;
  amount: number;
  created: number;
  currency: string;
  captured: boolean;
  paid: boolean;
  failure_code: string | null;
  failure_message: string | null;
  payment_method: PaymentMethod;
  receipt_url: string;
  status: string;
}

export interface StatusTransitions {
  finalized_at: number;
  marked_uncollectible_at: number | null;
  paid_at: number | null;
  voided_at: number | null;
}

export interface Invoice {
  _meta: Meta;
  id: string;
  total: number;
  currency: string;
  period_start: number;
  period_end: number;
  hosted_invoice_url: string;
  created: number;
  status: string;
  paid: boolean;
  charge: Charge;
  status_transitions: StatusTransitions;
}

export interface UpcomingInvoice {
  total: number;
  currency: string;
  period_start: number;
  period_end: number;
  next_payment_attempt: number;
}

export interface Invoices {
  _meta: Meta;
  _items: Invoice[];
  _status: Status;
}

export interface Customer {
  id: string;
  delinquent: boolean;
  email: string;
}

export interface SubscriptionAccount {
  email: string | null;
  support_phone: string | null;
  support_url: string | null;
  display_name: string | null;
}

export interface Subscription {
  _meta: Meta;
  id: string;
  billing_cycle_anchor: number;
  cancel_at: number | null;
  created: number;
  current_period_end: number;
  current_period_start: number;
  customer: Customer;
  latest_invoice: Invoice;
  payment_method: PaymentMethod;
  plan: Plan;
  publishable_key: string;
  start_date: number;
  status: SubscriptionStatus;
  trial_end: number | null;
  trial_start: number | null;
  upcoming_invoice: UpcomingInvoice | null;
  account: SubscriptionAccount;
}

export enum SubscriptionStatus {
  incomplete = "incomplete",
  incompleteExpired = "incomplete_expired",
  trialing = "trialing",
  active = "active",
  pastDue = "past_due",
  canceled = "canceled",
  unpaid = "unpaid",
}

export enum SubscriptionStatusDisplay {
  incomplete = "Incomplete",
  incomplete_expired = "Incomplete, Expired",
  trialing = "Trialing",
  active = "Active",
  past_due = "Past Due",
  canceled = "Canceled",
  unpaid = "Unpaid",
}
