import { productTheme } from "@lp/ui";

export const THEME = productTheme({});

export const STRIPE_BASE_STYLES = {
  base: {
    color: "#8C8C8C",
    fontSize: "15px",
    fontFamily: THEME.typography.fontFamily,
  },
};
