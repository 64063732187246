/* @ts-nocheck */
import React from "react";
import { CardElement } from "react-stripe-elements";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { STRIPE_BASE_STYLES } from "../stripeStyles";

const useStyles = makeStyles(
  () =>
    createStyles({
      description: {
        fontSize: "16px",
        color: "#202528",
        marginBottom: "20px",
      },
      cardInputTitle: {
        color: "#4B4B4B",
      },
      textField: {
        display: "flex",
      },
      stripe: {
        boxSizing: "border-box",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderWidth: "1px",
        borderStyle: "solid",
        padding: "14px 8px",
        "&.StripeElement--focus": {
          borderColor: "#603eff",
          borderWidth: "2px",
        },
        "&:hover&:not(.StripeElement--focus)": {
          borderColor: "rgb(9,12,18)",
        },
      },
    }),
  {
    classNamePrefix: "EditCard",
  },
);

interface EditCardFormProps {
  onChange: (chargeObject: stripe.elements.ElementChangeResponse) => void;
  cardError: string;
  setCardName: (name: string) => void;
}

export function EditCardForm({
  onChange,
  cardError,
  setCardName,
}: EditCardFormProps): React.ReactElement {
  const classes = useStyles();

  function onCardNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    setCardName(event.target.value);
  }

  const errorMessage = cardError ? (
    <Alert severity="error" style={{ marginBottom: "20px" }}>
      {cardError}
    </Alert>
  ) : null;

  return (
    <Box style={{ maxWidth: "380px" }}>
      {errorMessage}
      <Typography variant="body1" className={classes.description}>
        Enter your payment details to activate your subscription.
      </Typography>
      <Typography variant="body1" className={classes.cardInputTitle}>
        Name on Card
      </Typography>
      <form noValidate autoComplete="off">
        <TextField
          id="cardName"
          data-testid="card-name-text-field"
          variant="outlined"
          className={classes.textField}
          onChange={onCardNameChange}
        />
      </form>
      <Typography
        variant="body1"
        className={classes.cardInputTitle}
        style={{ marginTop: "16px", marginBottom: "4px" }}
      >
        Credit Card
      </Typography>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justify="center"
        style={{ paddingBottom: "20px" }}
      >
        <Grid item xs={12}>
          <CardElement
            data-testid="stripe-card-element"
            id="stripe-card-element"
            className={classes.stripe}
            onChange={onChange}
            style={STRIPE_BASE_STYLES}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
