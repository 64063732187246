import React, {
  useCallback,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";
import {
  makeStyles,
  createStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ConfirmDialog } from "@lp/ui";

import * as API from "../types/API";
import * as atm from "../atm";
import { formatCurrency } from "../currency";
import { EditCardForm } from "./EditCardForm";
import { useStripe } from "./Stripe";

import { ReactComponent as AmexLogo } from "../images/card_logos/amex.svg";
import { ReactComponent as DinersLogo } from "../images/card_logos/diners.svg";
import { ReactComponent as DiscoverLogo } from "../images/card_logos/discover.svg";
import { ReactComponent as JCBLogo } from "../images/card_logos/jcb.svg";
import { ReactComponent as MasterCardLogo } from "../images/card_logos/mastercard.svg";
import { ReactComponent as UnionPayLogo } from "../images/card_logos/unionpay.svg";
import { ReactComponent as VisaLogo } from "../images/card_logos/visa.svg";
import { ReactComponent as GenericCardLogo } from "../images/card_logos/generic.svg";

const CARD_LOGO_MAP = {
  // These keys should match ATM's schema for `brand`.
  amex: AmexLogo,
  diners: DinersLogo,
  discover: DiscoverLogo,
  jcb: JCBLogo,
  mastercard: MasterCardLogo,
  unionpay: UnionPayLogo,
  visa: VisaLogo,
  unknown: GenericCardLogo,
};

const CANCEL_BUTTON_PROPS = { style: { color: "rgba(15,12,9,0.5)" } };

const textColor = "rgba(15,12,9,0.7)";
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      editCardDialog: {
        [theme.breakpoints.down("xs")]: {
          "& .MuiDialogContent-root": {
            padding: "8px 10px",
          },
          "& .MuiDialog-paper": {
            margin: "10px",
          },
        },
      },
      tableHeader: {
        padding: "7px",
      },
      tableRowHeader: {
        padding: "7px",
      },
      tableRow: {},
      tableRowMobile: {
        "&:not(:last-child)": {
          borderBottom: "1px solid #D4D9DC",
        },
      },
      tableCellHeader: {
        padding: "7px",
        fontWeight: "normal",
      },
      tableCell: {
        color: textColor,
        borderBottom: 0,
        padding: "7px",
      },
      tableCellMobileKey: {
        color: textColor,
        padding: "7px",
        fontWeight: "normal",
        lineHeight: "2rem",
        border: "0px",
      },
      tableCellMobileValue: {
        color: textColor,
        textAlign: "right",
        lineHeight: "2rem",
        padding: "0px",
        border: "0px",
      },
      subtitle: {
        letterSpacing: "0.7px",
        fontSize: "14px",
        color: "#0f0c09",
        lineHeight: "19px",
        margin: "10px",
      },
      capitalizeFirstLetter: {
        "&:first-letter": {
          textTransform: "capitalize",
        },
      },
      gridContainer: {
        alignItems: "center",
        lineHeight: "2rem",
      },
      // The identifer for the row.
      rowName: {
        color: textColor,
      },
      // The content of a row.
      rowValue: {
        color: textColor,
      },
      subscriptionRowGrid: {
        justifyContent: "space-between",
      },
      subscriptionRowGridTopAligned: {
        [theme.breakpoints.up("sm")]: {
          alignItems: "normal",
          marginTop: "5px",
        },
      },
    }),
  {
    classNamePrefix: "SubscriptionManagerInfo",
  },
);

export function footer(subscription: API.Subscription): React.ReactFragment {
  return (
    <React.Fragment>
      <Typography align="center" style={{ marginTop: "24px" }}>
        Questions? Contact{" "}
        {subscription.account.display_name
          ? `${subscription.account.display_name} at `
          : ""}
        <a href={`mailto:${subscription.account.email}`}>
          {subscription.account.email}
        </a>
        {subscription.account.support_phone ? (
          <React.Fragment>
            {" or call "}
            <a href={`tel:${subscription.account.support_phone}`}>
              {subscription.account.support_phone}
            </a>
          </React.Fragment>
        ) : (
          ""
        )}
        .
      </Typography>
    </React.Fragment>
  );
}

function formatDate(epoch: number): string {
  return new Date(epoch * 1000).toLocaleDateString();
}

// An accessor just to ensure SubscriptionStatusDisplay has valid statuses
function getStatusDisplay(status: API.SubscriptionStatus): string {
  return API.SubscriptionStatusDisplay[status];
}

interface SubscriptionRowProps {
  name: string;
  button?: React.ReactFragment | null;
  topAligned?: boolean;
}

function SubscriptionRowContent(
  props: PropsWithChildren<SubscriptionRowProps>,
): React.ReactElement {
  const classes = useStyles();
  const { button, name, topAligned, children } = props;
  /* The top margin aligns the Payments row with the rest of the rows, which are
     centered instead. This is because payment history could be much larger than
     other rows, and we want its content labeled without having to scroll. */
  const topGridClass = topAligned
    ? classes.subscriptionRowGridTopAligned
    : classes.subscriptionRowGrid;
  return (
    <React.Fragment>
      <Grid
        item
        container
        sm={3}
        xs={12}
        className={`${classes.gridContainer} ${topGridClass}`}
      >
        <Grid item>
          <Typography variant="h6" component="p" className={classes.rowName}>
            {name}
          </Typography>
        </Grid>
        <Hidden smUp={true}>
          <Grid item>{button}</Grid>
        </Hidden>
      </Grid>
      <Grid item sm={9} xs={12}>
        <Typography
          variant="body1"
          component="span"
          className={classes.rowValue}
        >
          {children}
        </Typography>
      </Grid>
    </React.Fragment>
  );
}

function SubscriptionRow({
  button,
  name,
  children,
}: PropsWithChildren<SubscriptionRowProps>): React.ReactElement {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container item xs={12} className={classes.gridContainer}>
        <SubscriptionRowContent button={button} name={name}>
          {children}
        </SubscriptionRowContent>
      </Grid>
    </React.Fragment>
  );
}

function getInvoiceHistory(
  atmBaseUrl: string,
  subscriptionToken: string,
): Promise<API.Invoices> {
  return atm.getSubscriptionInvoices(atmBaseUrl, subscriptionToken);
}

interface InvoiceHistoryTableProps {
  invoiceHistory: API.Invoice[];
}

function InvoiceHistoryTable({
  invoiceHistory,
}: PropsWithChildren<InvoiceHistoryTableProps>): React.ReactElement {
  const classes = useStyles();
  const invoiceTable = (
    <Table>
      <TableHead>
        <TableRow className={classes.tableRowHeader}>
          <TableCell className={classes.tableCellHeader}>
            <Typography
              variant="body1"
              component="span"
              className={classes.rowValue}
            >
              Date
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCellHeader}>
            <Typography
              variant="body1"
              component="span"
              className={classes.rowValue}
            >
              Invoice ID
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCellHeader}>
            <Typography
              variant="body1"
              component="span"
              className={classes.rowValue}
            >
              Amount
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoiceHistory.map(invoice => {
          return (
            <TableRow
              key={invoice.id}
              className={classes.tableRow}
              hover={true}
            >
              <TableCell className={classes.tableCell}>
                <Typography variant="body1" component="span">
                  {formatDate(invoice.created)}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="body1" component="span">
                  {invoice.id}
                </Typography>
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ textAlign: "right" }}
              >
                <Typography variant="body1" component="span">
                  {formatCurrency(
                    navigator.language,
                    invoice.currency,
                    invoice.total,
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  const invoiceTableMobile = (
    <Table>
      <TableBody>
        {invoiceHistory.map(invoice => {
          return (
            <TableRow
              key={invoice.id}
              className={classes.tableRowMobile}
              hover={true}
            >
              <TableCell className={classes.tableCellMobileKey}>
                Date
                <br />
                Amount
                <br />
                ID
              </TableCell>
              <TableCell className={classes.tableCellMobileValue}>
                <Typography variant="body1" component="span">
                  {formatDate(invoice.created)}
                  <br />
                  {formatCurrency(
                    navigator.language,
                    invoice.currency,
                    invoice.total,
                  )}
                  <br />
                  {invoice.id}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return (
    <React.Fragment>
      <Hidden xsDown>{invoiceTable}</Hidden>
      <Hidden smUp>{invoiceTableMobile}</Hidden>
    </React.Fragment>
  );
}

interface SubscriptionManagerInfoProps {
  atmBaseUrl: string;
  refreshSubscription: () => Promise<void>;
  subscription: API.Subscription;
  subscriptionToken: string;
}

export default function SubscriptionManagerInfo({
  atmBaseUrl,
  subscription,
  subscriptionToken,
  refreshSubscription,
}: SubscriptionManagerInfoProps): React.ReactElement {
  const [stripe, elements] = useStripe();
  const [invoiceHistory, setInvoiceHistory] = useState<API.Invoice[]>();
  const [isFetchingInvoiceHistory, setIsFetchingInvoiceHistory] = useState(
    false,
  );
  const [cancelSubscriptionIsOpen, setCancelSubscriptionIsOpen] = useState(
    false,
  );
  const [editCardIsOpen, setEditCardIsOpen] = useState(false);
  const [cardIsValid, setCardIsValid] = useState(false);
  const [cardIsSaving, setCardIsSaving] = useState(false);
  const [
    cardElement,
    setCardElement,
  ] = useState<stripe.elements.Element | null>(null);
  const [cardName, setCardName] = useState("");
  const [cardError, setCardError] = useState("");
  const [cancelError, setCancelError] = useState("");

  const classes = useStyles();
  const theme = useTheme();
  const xsMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const refreshInvoiceHistory = useCallback(async () => {
    setIsFetchingInvoiceHistory(true);
    const response = await getInvoiceHistory(atmBaseUrl, subscriptionToken);
    setInvoiceHistory(response._items);
    setIsFetchingInvoiceHistory(false);
  }, [atmBaseUrl, subscriptionToken]);

  function toggleEditCardIsOpen(): void {
    setEditCardIsOpen(!editCardIsOpen);
  }

  function toggleCancelSubscriptionIsOpen(): void {
    setCancelSubscriptionIsOpen(!cancelSubscriptionIsOpen);
  }

  async function cancelSubscription(): Promise<void> {
    try {
      await atm.cancelSubscription(atmBaseUrl, subscriptionToken);
    } catch (e) {
      return setCancelError(e.message);
    }
    setCancelSubscriptionIsOpen(false);
    await refreshSubscription();
  }

  async function saveCard(): Promise<void> {
    if (!cardElement) {
      setCardError("A card must be provided.");
      return;
    }

    setCardIsSaving(true);
    let result;
    try {
      // https://stripe.com/docs/js/payment_intents/create_payment_method
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      result = await (stripe! as stripe.Stripe).createPaymentMethod(
        "card",
        cardElement,
        {
          billing_details: {
            name: cardName,
          },
        },
      );
    } catch (error) {
      setCardIsSaving(false);
      setCardError("An error occurred, please try again later.");
      console.error("Failed to save card", error);
      return;
    }

    console.debug("Created Stripe PaymentMethod", result);

    if (result.error) {
      setCardError(result.error.message || "Failed to save card.");
      console.error("Failed saving card with Stripe API", result.error);
      setCardIsSaving(false);
      return;
    }

    if (result.paymentMethod) {
      const patch = {
        // https://stripe.com/docs/api/subscriptions/update#update_subscription-default_payment_method
        default_payment_method: result.paymentMethod.id,
      };
      try {
        await atm.patchSubscription(atmBaseUrl, subscriptionToken, patch);
      } catch (patchErr) {
        console.error("Failed patching subscription", patchErr);
        setCardError("Failed to update card, please try again later.");
        setCardIsSaving(false);
        return;
      }
      setCardError("");
      setEditCardIsOpen(false);
      setCardIsSaving(false);
      refreshSubscription();
      // ATM will pay past-due invoices immediately on PATCH to subscriptions.
      if (subscription.status === API.SubscriptionStatus.pastDue) {
        refreshInvoiceHistory();
      }
    }
  }

  useEffect(() => {
    refreshInvoiceHistory();
  }, [refreshInvoiceHistory]);

  function onEditCardChange(
    charge: stripe.elements.ElementChangeResponse,
  ): void {
    const isValid = charge.complete ? true : false;
    setCardIsValid(isValid);
    if (stripe && elements && isValid) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const element = (elements! as stripe.elements.Elements).getElement(
        "card",
      );
      setCardElement(element);
    }
  }

  const { brand, last4, exp_month, exp_year } = subscription.payment_method;

  const CardLogo = CARD_LOGO_MAP[brand] || CARD_LOGO_MAP.unknown;

  const editCardButton = (
    <React.Fragment>
      <Button
        variant="text"
        data-testid="edit-card-button"
        onClick={toggleEditCardIsOpen}
      >
        <Hidden smUp>Edit</Hidden>
        <Hidden xsDown>Edit Card</Hidden>
      </Button>
    </React.Fragment>
  );

  const paymentMethodRow = (
    <Grid container spacing={1} className={classes.gridContainer}>
      {/* display: flex centers the svg in the div. */}
      <Grid item style={{ width: "3.5rem", display: "flex" }}>
        <CardLogo
          height="100%"
          width="100%"
          title={brand}
          aria-label="Card Brand"
        />
      </Grid>
      <Grid item>
        <Typography>{`•••• ${last4}`}</Typography>
      </Grid>
      <Grid item>
        <Typography
          style={{ marginLeft: "15px" }}
          aria-label="Expiry Date"
        >{`${exp_month} / ${exp_year.toString().slice(-2)}`}</Typography>
      </Grid>
      <Hidden xsDown>
        <Grid item style={{ marginLeft: "auto" }}>
          {editCardButton}
        </Grid>
      </Hidden>
    </Grid>
  );

  const paymentRows =
    subscription.status !== API.SubscriptionStatus.canceled ? (
      <React.Fragment>
        <ConfirmDialog
          type="confirm"
          open={editCardIsOpen}
          titleText="Edit Card"
          cancelButtonText="Cancel"
          confirmButtonText="Save"
          onConfirm={saveCard}
          onClose={toggleEditCardIsOpen}
          CancelButtonProps={CANCEL_BUTTON_PROPS}
          ConfirmButtonProps={{ disabled: !cardIsValid || cardIsSaving }}
          className={classes.editCardDialog}
        >
          <EditCardForm
            onChange={onEditCardChange}
            cardError={cardError}
            setCardName={setCardName}
          />
        </ConfirmDialog>
        <SubscriptionRow name="Payment Method" button={editCardButton}>
          {paymentMethodRow}
        </SubscriptionRow>
        <SubscriptionRow name="Next Payment Date">
          {subscription.upcoming_invoice
            ? formatDate(subscription.upcoming_invoice.next_payment_attempt)
            : "None"}
        </SubscriptionRow>
        <Grid container item>
          <SubscriptionRowContent name="Payments" topAligned={true}>
            {isFetchingInvoiceHistory ? (
              <LinearProgress />
            ) : (
              <InvoiceHistoryTable invoiceHistory={invoiceHistory || []} />
            )}
          </SubscriptionRowContent>
        </Grid>
      </React.Fragment>
    ) : null;

  const cancelButton = (
    <div>
      <Button
        variant="text"
        data-testid="cancel-button"
        onClick={toggleCancelSubscriptionIsOpen}
      >
        {xsMobile ? "Cancel" : "Cancel Subscription"}
      </Button>
    </div>
  );

  const statusButton =
    subscription.status !== API.SubscriptionStatus.canceled
      ? cancelButton
      : null;

  const statusRowContent = (
    <React.Fragment>
      <ConfirmDialog
        type="confirm"
        open={cancelSubscriptionIsOpen}
        titleText="Cancel Subscription"
        cancelButtonText="Close"
        confirmButtonText={xsMobile ? "Cancel" : "Cancel Subscription"}
        onConfirm={cancelSubscription}
        onClose={toggleCancelSubscriptionIsOpen}
        CancelButtonProps={CANCEL_BUTTON_PROPS}
      >
        <Typography>
          Are you sure you want to cancel your subscription?
        </Typography>
        {cancelError ? (
          <Alert
            style={{ marginTop: "25px", marginBottom: "25px" }}
            severity="error"
          >
            {cancelError}
          </Alert>
        ) : null}
      </ConfirmDialog>

      <Grid container className={classes.gridContainer}>
        <Grid item>
          <Typography>{getStatusDisplay(subscription.status)}</Typography>
        </Grid>
        <Hidden xsDown>
          <Grid item style={{ marginLeft: "auto" }}>
            {statusButton}
          </Grid>
        </Hidden>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Typography className={classes.subtitle} variant="subtitle2">
        SUBSCRIPTION DETAILS
      </Typography>
      <SubscriptionRow name="Product">
        {subscription.plan.product.name}
      </SubscriptionRow>
      <SubscriptionRow name="Subscription ID">
        {subscription.id}
      </SubscriptionRow>
      <SubscriptionRow name="Subscription Status" button={statusButton}>
        {statusRowContent}
      </SubscriptionRow>
      {paymentRows}
      <Grid item xs={12}>
        <Divider />
        {footer(subscription)}
      </Grid>
    </React.Fragment>
  );
}
