// See https://github.com/LeadPages/crucible/blob/stripe-sub/src/utils/payments.js

// Zero-decimal currencies from Braintree
// https://developers.braintreepayments.com/reference/general/currencies
const ZERO_DECIMAL_CURRENCIES = [
  "BIF", // Burundian Franc
  "CLP", // Chilean Peso
  "DJF", // Djiboutian Franc
  "GNF", // Guinean Franc
  "JPY", // Japanese Yen
  "KMF", // Comorian Franc
  "KRW", // South Korean Won
  "LAK", // Lao Kip
  "PYG", // Paraguayan Guaraní
  "RWF", // Rwandan Franc
  "UGX", // Ugandan Shilling
  "VND", // Vietnamese Đồng
  "VUV", // Vanuatu Vatu
  "XAF", // Central African Cfa Franc
  "XOF", // West African Cfa Franc
  "XPF", // Cfp Franc
];

export function formatCurrency(
  locales: string,
  currency: string,
  amount: number,
): string {
  let value = amount;
  if (value > 0 && !ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase())) {
    value /= 100;
  }
  return new Intl.NumberFormat(locales, { style: "currency", currency }).format(
    value,
  );
}

export default {};
