/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect, useContext } from "react";
import {
  StripeProvider as StripeProviderInner,
  Elements as ElementsInner,
  injectStripe,
} from "react-stripe-elements";

export const StripeProvider = ({ apiKey, children }) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const initializeStripe = () => {
      setStripe(window.Stripe(apiKey));
    };
    if (window.Stripe) {
      initializeStripe();
    } else {
      // Otherwise wait for Stripe script to load
      document.querySelector("#stripe-js").addEventListener("load", () => {
        initializeStripe();
      });
    }
  }, [apiKey]);

  return <StripeProviderInner stripe={stripe}>{children}</StripeProviderInner>;
};

const HookContext = createContext([null, null]);
const HookProvider = injectStripe(({ stripe, elements, children }) => {
  return (
    <HookContext.Provider value={[stripe, elements]}>
      {children}
    </HookContext.Provider>
  );
});

export const Elements = ({ children }) => {
  return (
    <ElementsInner>
      <HookProvider>{children}</HookProvider>
    </ElementsInner>
  );
};

export const useStripe = () => useContext(HookContext);
