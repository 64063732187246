import * as API from "./types/API";
import HttpStatus from "./http_status_codes";

export async function getSubscription(
  atmBaseUrl: string,
  subscriptionId: string,
): Promise<API.Subscription> {
  let response;
  try {
    response = await window.fetch(
      `${atmBaseUrl}/v1/subscriptions/${subscriptionId}`,
      { headers: { "Content-Type": "application/json" } },
    );
  } catch (err) {
    // Fall through to default error message.
  }
  if (response) {
    if (response.status === HttpStatus.NOT_FOUND) {
      throw Error("Subscription not found.");
    }
    if (response.status !== HttpStatus.OK) {
      throw Error(
        "An error occurred fetching the subscription. Please try again later.",
      );
    }
    return response.json();
  }
  throw Error(
    "An error occurred fetching the subscription. Please try again later.",
  );
}

export async function patchSubscription(
  atmBaseUrl: string,
  subscriptionId: string,
  patch: object,
): Promise<API.Subscription> {
  let response;
  try {
    response = await window.fetch(
      `${atmBaseUrl}/v1/subscriptions/${subscriptionId}`,
      {
        headers: { "Content-Type": "application/json" },
        method: "PATCH",
        body: JSON.stringify(patch),
      },
    );
  } catch (err) {
    // Fall through to default error message.
  }
  if (response) {
    if (response.status === HttpStatus.NOT_FOUND) {
      throw Error("Subscription not found.");
    }
    if (response.status !== HttpStatus.OK) {
      throw Error(
        "An error occurred updating the subscription. Please try again later.",
      );
    }
    return response.json();
  }
  throw Error(
    "An error occurred updating the subscription. Please try again later.",
  );
}

export async function getSubscriptionInvoices(
  atmBaseUrl: string,
  subscriptionId: string,
): Promise<API.Invoices> {
  let response;
  try {
    response = await window.fetch(
      `${atmBaseUrl}/v1/subscriptions/${subscriptionId}/invoices`,
      { headers: { "Content-Type": "application/json" } },
    );
  } catch (err) {
    // Fall through to default error message.
  }
  if (response) {
    if (response.status === HttpStatus.NOT_FOUND) {
      throw Error("Subscription not found.");
    }
    if (response.status !== HttpStatus.OK) {
      throw Error(
        "An error occurred fetching subscription invoices. Please try again later.",
      );
    }
    return response.json();
  }
  throw Error(
    "An error occurred fetching subscription invoices. Please try again later.",
  );
}

export async function cancelSubscription(
  atmBaseUrl: string,
  subscriptionId: string,
): Promise<void> {
  const response = await fetch(
    `${atmBaseUrl}/v1/subscriptions/${subscriptionId}`,
    { method: "DELETE" },
  );
  if (response.status === HttpStatus.NOT_FOUND) {
    throw Error("Subscription not found.");
  }
  if (response.status !== HttpStatus.OK) {
    throw Error("Unable to cancel your account. Please try again.");
  }
}

export default {};
