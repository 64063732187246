import React, {
  useCallback,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ShadowBox } from "@lp/ui";

import * as API from "../types/API";
import * as atm from "../atm";
import SubscriptionManagerInfo from "./SubscriptionManagerInfo";
import { StripeProvider, Elements } from "./Stripe";

interface Props {
  atmBaseUrl: string;
}

const textColor = "rgba(15,12,9,0.7)";
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      subscriptionRowGridContainer: {
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        color: textColor,
      },
      paymentShadowBox: {
        [theme.breakpoints.down("xs")]: {
          padding: "14px",
        },
      },
    }),
  {
    classNamePrefix: "SubscriptionManager",
  },
);
const alertGridStyle = {
  padding: "30px",
};

function PaymentShadowBox(props: PropsWithChildren<{}>): React.ReactElement {
  const classes = useStyles();
  return (
    <ShadowBox
      maxWidth="676px"
      margin="auto"
      className={classes.paymentShadowBox}
    >
      {props.children}
    </ShadowBox>
  );
}

interface PaymentGridProps extends PropsWithChildren<{}> {
  style?: object;
}

function PaymentGrid({
  style,
  children,
}: PaymentGridProps): React.ReactElement {
  const classes = useStyles();
  return (
    <PaymentShadowBox>
      <Grid
        container
        spacing={2}
        className={classes.subscriptionRowGridContainer}
        style={style}
      >
        {children}
      </Grid>
    </PaymentShadowBox>
  );
}

export default function SubscriptionManager({
  atmBaseUrl,
}: Props): React.ReactElement {
  const { subscriptionId } = useParams();
  const [subscription, setSubscription] = useState<API.Subscription>();
  const [subscriptionFetchError, setSubscriptionFetchError] = useState<
    string
  >();

  const refreshSubscription = useCallback(async () => {
    if (!subscriptionId) {
      return;
    }
    try {
      const sub = await atm.getSubscription(atmBaseUrl, subscriptionId);
      setSubscription(sub);
    } catch (err) {
      setSubscriptionFetchError(err.message);
    }
  }, [atmBaseUrl, subscriptionId]);

  useEffect(() => {
    refreshSubscription();
  }, [refreshSubscription]);

  if (!subscriptionId) {
    return (
      <PaymentGrid style={alertGridStyle}>
        <Alert severity="error">Subscription not found.</Alert>
      </PaymentGrid>
    );
  }

  if (!subscription) {
    return (
      <PaymentGrid style={alertGridStyle}>
        {subscriptionFetchError ? (
          <Alert severity="error">{subscriptionFetchError}</Alert>
        ) : (
          <CircularProgress data-testid="subscription-loading-progress-bar" />
        )}
      </PaymentGrid>
    );
  }

  return (
    <StripeProvider apiKey={subscription.publishable_key}>
      <Elements>
        <PaymentGrid>
          <SubscriptionManagerInfo
            atmBaseUrl={atmBaseUrl}
            subscription={subscription}
            refreshSubscription={refreshSubscription}
            subscriptionToken={subscriptionId}
          />
        </PaymentGrid>
      </Elements>
    </StripeProvider>
  );
}
