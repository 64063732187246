import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { ProductThemeProvider } from "@lp/ui";
import SubscriptionManager from "./components/SubscriptionManager";
import { Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import config from "./config";

const useStyles = makeStyles(
  () =>
    createStyles({
      app: {},
      title: {
        textAlign: "center",
        fontSize: "25px",
        paddingTop: "45px",
        paddingBottom: "45px",
        fontWeight: "bold",
        lineHeight: "34px",
      },
      header: {
        backgroundColor: "#603eff",
        color: "white",
        height: "210px",
        marginBottom: "-86px",
      },
    }),
  {
    classNamePrefix: "App",
  },
);

function NotFound(): React.ReactElement {
  return <h1>Not Found</h1>;
}

const App: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <ProductThemeProvider>
        <Router>
          <Switch>
            <Route path="/subscriptions/:subscriptionId">
              <header className={classes.header}>
                <Typography
                  variant="h1"
                  component="div"
                  className={classes.title}
                >
                  Manage Subscription
                </Typography>
              </header>
              <SubscriptionManager atmBaseUrl={config.atmBaseUrl} />
            </Route>
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </ProductThemeProvider>
    </div>
  );
};

export default App;
